<template>
  <!-- begin:: Header -->
  <div
    id="kt_top_header"
    ref="kt_top_header"
    class="top-header"
    v-bind:class="headerClasses"
  >
    <div
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <b-row style="position: relative" class="top-header-content" align-h="between" align-v="center">
        <b-col cols="2" class="d-none d-lg-block">
          <a href="/">
            <img
              class="img-fluid top-header-img"
              :src="headerLogo"
              alt="Logo VFXanh"
            />
          </a>
        </b-col>
        <b-col cols="2" v-if="isNewLayout" style="position: absolute; left: 200px">
          <template
            data-v-6c7fd7ba=""
            class="navi navi-spacer-x-0 p-0 my-2"
            style="margin-right: 12px; margin-top:2px"
          >
            <b-form-select
              size="sm"
              v-model="selectedMerchant"
              :options="merchantOptions"
              style="width: fit-content !important; font-weight: bold; font-size: 12px"
              @change="onChangeMerchant"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Chọn công ty --</b-form-select-option
                >
              </template>
            </b-form-select>
          </template>
        </b-col>
        <b-col cols="3">
          <KTTopbar></KTTopbar>
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTTopbar from '@/view/layout/header/Topbar.vue';
import KTLayoutTopHeader from '@/assets/js/layout/base/header-top.js';
import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';
import SavaDataUtil from '@/utils/saveDataToLocal';


export default {
  name: 'KTTopHeader',
  components: {
    KTTopbar,
  },
  data() {
    return {
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'VFXanh' },
      ],
    }
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutTopHeader.init('kt_top_header', 'kt_top_header_mobile');

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs['kt_top_header_menu'],
      this.$refs['kt_top_header_menu_wrapper']
    );

    this.selectedMerchant = SavaDataUtil.getData('merchantId') || 1;
  },
  methods: {
    onChangeMerchant() {
      SavaDataUtil.saveData('merchantId', this.selectedMerchant);
      this.$router.go(this.$router.currentRoute);
    },
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig('self.logo.brand');
    },

    widthFluid() {
      console.log(
        'this.layoutConfig',
        this.layoutConfig('topHeader.self.width')
      );
      return this.layoutConfig('topHeader.self.width') === 'fluid';
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig('header.menu.self.display');
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('top-header');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses('header_menu');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },

    isNewLayout(){
      if (SavaDataUtil.getData('config')) return true 
      return false;
    },
  }
};
</script>
