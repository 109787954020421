<template>
  <ul class="menu-nav">
    <router-link
      v-if="false"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/products"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermission('PRODUCT_VIEW') ||
        checkPermission('CATE_VIEW') ||
        checkPermission('PROPERTY_VIEW') ||
        checkPermission('PROVIDER_VIEW') ||
        checkPermission('BRAND_VIEW')
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Sản phẩm</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/customers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermission('CUSTOMER_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Khách hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/stocks"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermission('STOCK_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <small class="menu-text">Kho hàng</small>
        </a>
      </li>
    </router-link>

    <router-link
      to="/orders"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['ORDER_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Đơn hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/bills"
      v-if="checkPermission('BILL_VIEW')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Bán hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/trade-in"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['TRADE_IN_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Thu cũ</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/accounting/transactions/cashbook"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermissions([
          'TRANSACTION_VIEW',
          'INSTALLMENT_VIEW',
          'DEBT_CREDIT_VIEW',
          'DEBT_INSTALLMENT_VIEW',
          'DEBT_ACCOUNTANT_VIEW',
          'ACCOUNTANT_VIEW',
        ])
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Kế toán</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/reports"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['REPORT_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Báo cáo</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/reward/reward-category"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="checkPermissions(['REWARD_VIEW'])"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Hoa hồng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/stores"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        checkPermission('STORE_VIEW') || checkPermission('ORDER_SOURCE_VIEW')
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Cấu hình</span>
        </a>
      </li>
    </router-link>

    <div>
      <b-form-select
        size="sm"
        v-model="selectedMerchant"
        :options="merchantOptions"
        class="dropdown-custom"
        @change="onChangeMerchant"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Chọn công ty --</b-form-select-option
          >
        </template>
      </b-form-select>
    </div>

    <b-dropdown size="sm" right class="quick-link" no-caret>
      <template slot="button-content">
        <i class="fa-solid fa-circle-plus ml-1 text-dark"></i>
      </template>
      <template v-for="(item, index) in dropDownLink">
        <template
          v-if="
            item.permit && item.permit.length
              ? checkPermissions(item.permit)
              : true
          "
        >
          <b-dropdown-item
            :key="index"
            :to="
              item.external
                ? item.link
                : { path: item.link, target: item.target || '' }
            "
            :href="item.external ? item.link : ''"
            :target="item.target || ''"
          >
            <span>
              <i
                class="fa-solid fa-arrow-up-right-from-square text-dark mr-1"
                style="font-size: 1rem"
              ></i>
              {{ item.name }}</span
            >
          </b-dropdown-item>
        </template>
      </template>
    </b-dropdown>
  </ul>
</template>
<script>
import SavaDataUtil from '@/utils/saveDataToLocal';
export default {
  data() {
    return {
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'VFXanh' },
      ],
      dropDownLink: [
        {
          name: 'Sản phẩm',
          link: '/products/add-product',
          permit: ['PRODUCT_INSERT'],
        },
        {
          name: 'Đơn hàng',
          link: '/orders/add-order',
          permit: ['ORDER_INSERT'],
        },
        {
          name: 'Bán lẻ',
          link: '/bills/add-bill',
          permit: ['BILL_INSERT'],
        },
        {
          name: 'Bán buôn',
          link: '/wholesaleInvoices/add-wholesale-invoice',
          permit: ['BILL_INSERT'],
        },
        {
          name: 'Nhập kho',
          link: '/stocks/add-stock',
          permit: ['STOCK_IMPORT_INSERT'],
        },
        {
          name: 'Xuất kho',
          link: '/export-stocks/add-export-stock',
          permit: ['STOCK_EXPORT_INSERT'],
        },
        {
          name: 'Chuyển kho',
          link: '/transfer-stocks/add-transfer-stock',
          permit: ['STOCK_INSERT'],
        },
        {
          name: 'Ý kiến đóng góp',
          link: 'https://docs.google.com/forms/d/e/1FAIpQLSetWcf1SRsZjz2fnvL7BlLvavccBI9b_nNh1LspDrd4XjTxBA/viewform',
          permit: [],
          external: true,
          target: '_blank',
        },
        {
          name: 'CTKM Marketing',
          link: 'https://drive.google.com/drive/folders/1Uy-0IDztoHR2-Bx6TQ7SK2agOCCpfuWa',
          permit: [],
          external: true,
          target: '_blank',
        },
      ],
    };
  },
  name: 'KTMenu',
  mounted() {
    this.selectedMerchant = SavaDataUtil.getData('merchantId') || 1;
  },
  methods: {
    onChangeMerchant() {
      SavaDataUtil.saveData('merchantId', this.selectedMerchant);
      this.$router.go(this.$router.currentRoute);
    },
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    checkPermission(condition) {
      return !!SavaDataUtil.checkPermission(condition);
    },
    checkPermissions(conditions) {
      return !!SavaDataUtil.checkPermissions(conditions);
    },
  },
};
</script>
<style scoped lang="scss">
.quick-link::v-deep {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0rem 0.3rem;
  }
  .btn.btn-secondary {
    background-color: transparent;
    border: none;
  }
  .dropdown-item {
    padding: 0.5rem 1.25rem;
  }
}
.font-style {
  font-size: 0.8rem;
}
</style>